import { render, staticRenderFns } from "./partDetailPageAppsPassengerCar.vue?vue&type=template&id=ebcf9200&"
import script from "./partDetailPageAppsPassengerCar.vue?vue&type=script&lang=js&"
export * from "./partDetailPageAppsPassengerCar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/apps/jenkins/workspace/AEM_QA_6.5_PUBLISHER/aem-base/content/src/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ebcf9200')) {
      api.createRecord('ebcf9200', component.options)
    } else {
      api.reload('ebcf9200', component.options)
    }
    module.hot.accept("./partDetailPageAppsPassengerCar.vue?vue&type=template&id=ebcf9200&", function () {
      api.rerender('ebcf9200', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "content/jcr_root/apps/fmmp-base/components/content/driv-part-finder/driv-part-detail-page/clientlibs/src/part-detail-tabs/partDetailPageAppsPassengerCar.vue"
export default component.exports